import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';

import image from '../images/Zèbres.jpg'

const atEnfants = ({ data }) => {
  const quote = <><b>"ni hérisson, ni paillasson"</b></>
  const prereq = <><b style={{ color:"green" }}>Aucun prérequis équestre n'est nécessaire, ON NE MONTE PAS LE CHEVAL.</b></>;

  return (
    <Layout>
      <SEO title="Enfants" />
      <Header metadata={data.site.siteMetadata} />
      <br/>
      {prereq}
      <br/><br/>
      Pour soutenir l'enfant dans la gestion de ses émotions et lui permettre d'être l'adulte de demain ancré et libre d'être lui-même, j'organise régulièrement des ateliers en groupe intitulés {quote} lui permettant de découvrir et d'appliquer les outils tels que:<br/><br/>
      * La communication non violente<br/>
      * Les Accords Toltèques<br/>
      * La méthode Thomas-Kilmann<br/>
      Tout ça dans l'empathie et la bienveillance
      <br/><br/><br/>
      Êquicoaching = équidé = âne, cheval, zèbre ou licorne: quel équidé es-tu?
      <br/><br/>
      <img width={450} height={600} src={image} alt=""/>
    </Layout>
  );
};

export default atEnfants;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
